import { IUserRaw } from './AuthTypes';

export const login = () => ({
  type: 'LOGIN',
});

export const loginSucceeed = (data: IUserRaw) => ({
  type: 'LOGIN_SUCCEEDED',
  jwt: data.jwt,
  user: data.user,
});

export const loginFailed = ({
  message,
  isSocial,
}: {
  message: string;
  isSocial?: boolean;
}) => ({
  type: 'LOGIN_FAILED',
  errorMessage: message,
  isSocial,
});
export const logout = () => ({
  type: 'LOGOUT',
});
