import React, { useCallback, useEffect } from 'react';
import {
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonList,
  IonMenuButton,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { connect } from 'react-redux';
import { selectSortedOrders } from '../domains/shop/ShopSelectors';
import { IOrders, sortedOrderMomentKey } from '../domains/shop/ShopTypes';
import { IStoreState } from '../types/ReduxTypes';
import { apiRequest } from '../api/ApiActions';
import Order from '../components/Order/Order';
import styles from './OrdersScreen.module.scss';
import { RouteComponentProps } from 'react-router';

interface IProps
  extends RouteComponentProps<{
    momentKey: sortedOrderMomentKey;
  }> {
  orders: IOrders;
  fetchOrders: (stopRefreshIndicator?: Function) => any;
  validateOrder: (ordrrId: number) => any;
  markOrderAsReady: (ordrrId: number) => any;
  markOrderAsPickedUp: (ordrrId: number) => any;
}

const OrdersScreen: React.FC<IProps> = (props) => {
  const {
    orders,
    fetchOrders,
    validateOrder,
    markOrderAsReady,
    markOrderAsPickedUp,
  } = props;

  const noOrders = !orders.length;

  const resresh = useCallback(
    (e: any) => {
      fetchOrders(e.detail.complete);
    },
    [fetchOrders]
  );

  useEffect(() => {
    fetchOrders();
  }, []);

  // Interval
  useEffect(() => {
    const interval = setInterval(() => {
      const isFocused = props.history.location.pathname.includes('orders/');
      if (isFocused) {
        fetchOrders();
      }
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Orders</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Orders</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="ion-padding">
          <IonRefresher slot="fixed" onIonRefresh={resresh}>
            <IonRefresherContent />
          </IonRefresher>

          <IonList style={{ backgroundColor: 'transparent' }}>
            {/* <IonListHeader>We have products that fit</IonListHeader> */}
            <IonGrid>
              <IonRow>
                {orders?.map((order) => (
                  <IonCol
                    key={order.id}
                    sizeXl="3"
                    sizeLg="4"
                    sizeMd="6"
                    sizeSm="6"
                    sizeXs="12"
                  >
                    <Order
                      order={order}
                      validateOrder={validateOrder}
                      markOrderAsReady={markOrderAsReady}
                      markOrderAsPickedUp={markOrderAsPickedUp}
                    />
                  </IonCol>
                ))}
                {noOrders && (
                  <div className="container">
                    <IonText>
                      <h2>No orders yet</h2>
                    </IonText>
                  </div>
                )}
              </IonRow>
            </IonGrid>
          </IonList>
        </div>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state: IStoreState, props: IProps) => {
  const { momentKey } = props.match.params;
  const sortedOrders = selectSortedOrders(state);

  return {
    orders: sortedOrders[momentKey],
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchOrders: (stopRefreshIndicator?: Function) => {
      dispatch(apiRequest('fetchOrders', { stopRefreshIndicator }));
    },
    validateOrder: (orderId: number) => {
      dispatch(apiRequest('validateOrder', { orderId }));
    },
    markOrderAsReady: (orderId: number) => {
      dispatch(apiRequest('markOrderAsReady', { orderId }));
    },
    markOrderAsPickedUp: (orderId: number) => {
      dispatch(apiRequest('markOrderAsPickedUp', { orderId }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrdersScreen);
