import { all, takeEvery, put } from 'redux-saga/effects';
import { Dispatch, AnyAction } from 'redux';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { IStoreState } from '../../types/ReduxTypes';
import { ThunkAction } from 'redux-thunk';
import { environment } from '../../config/environments';
import { pushNotificationsRegistration } from './PushNotificationsActions';
import { isPlatform } from '@ionic/react';

/**
 * OneSignal Ionic SDK:
 * https://documentation.onesignal.com/docs/ionic-sdk-setup
 * https://documentation.onesignal.com/docs/cordova-sdk
 */

const initializeOneSignalService: ThunkAction<
  void,
  IStoreState,
  unknown,
  AnyAction
> = () => {
  return (dispatch: Dispatch, getState: () => IStoreState) => {
    const oneSignal = new OneSignal();

    oneSignal.startInit(
      environment.ONESIGNAL_APP_ID,
      environment.ONESIGNAL_GOOGLE_SENDER_ID
    );

    oneSignal.iOSSettings({
      kOSSettingsKeyAutoPrompt: true,
      kOSSettingsKeyInAppLaunchURL: false,
    });
    oneSignal.registerForPushNotifications();
    // The promptForPushNotificationsWithUserResponse function will show the iOS push notification prompt. We recommend removing the following code and instead using an In-App Message to prompt for notification permission (See step 6)
    oneSignal.promptForPushNotificationsWithUserResponse().then((accepted) => {
      console.log('User accepted notifications: ' + accepted);
    });

    oneSignal.inFocusDisplaying(oneSignal.OSInFocusDisplayOption.Notification);

    oneSignal.handleNotificationReceived().subscribe((notification: any) => {
      console.log(notification);
    });

    oneSignal
      .handleNotificationOpened()
      .subscribe((notificationResult: any) => {
        console.log(notificationResult);
      });

    oneSignal.endInit();

    oneSignal.getIds().then((ids) => {
      const { pushToken, userId } = ids;
      dispatch(pushNotificationsRegistration(userId, pushToken));
    });
  };
};

function* appStart() {
  //   const state = yield select((state) => state);

  if (isPlatform('capacitor')) {
    // TODO Loic typescript redux-thunk action called from redux-saga "put"
    // @ts-ignore
    yield put(initializeOneSignalService());
  }
}

const pushNotificationsSagas = function* pushNotificationsSagas(): any {
  yield all([takeEvery('APP_START', appStart)]);
};

export default [pushNotificationsSagas];
