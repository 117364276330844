import { createSelector } from 'reselect';
import { IStoreState } from '../../types/ReduxTypes';
import { IPushNotificationsState } from './PushNotificationsTypes';

const getPushNotifications: (state: IStoreState) => IPushNotificationsState = (
  state
) => state.pushNotifications;

export const selectPushUserId = createSelector(
  getPushNotifications,
  (getPushNotifications) => getPushNotifications.pushUserId
);
