/* @flow */
import { fork, all } from 'redux-saga/effects';
// import type { Saga as SagaType } from 'redux-saga';
import ApiSagas from '../api/ApiSagas';
import AuthSagas from '../domains/auth/AuthSagas';
import PushNotificationsSagas from '../domains/pushNotifications/PushNotificationsSagas';
import ShopSagas from '../domains/shop/ShopSagas';

const allSagas = [
  ...ApiSagas,
  ...AuthSagas,
  ...PushNotificationsSagas,
  ...ShopSagas,
];

export const sagas = function* sagas(): any {
  yield all(allSagas.map((saga) => fork(saga)));
};
