import { createSelector } from 'reselect';
import { IStoreState } from '../../types/ReduxTypes';
import { IShopState } from './ShopTypes';

const defaultCart = {}; // Default cart to avoid repetitive null management in components when the user doesn't have cart.

const getShop: (state: IStoreState) => IShopState = (state) => state.shop;

export const selectShopState = createSelector(getShop, (shop) => shop);

export const selectShop = createSelector(selectShopState, (shop) => shop.shop);

// export const selectProducts = createSelector(getShop,x (shop) => shop.products);

// export const selectCartFull = createSelector(
//   selectShopState,
//   (shop) => shop.cartFull || defaultCart
// );

// export const selectCartId = createSelector(selectShopState, (shop) => shop.cartId);

export const selectOrders = createSelector(
  selectShopState,
  (shop) => shop.orders
);

export const selectSortedOrders = createSelector(
  selectShopState,
  (shop) => shop.sortedOrders
);
