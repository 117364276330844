// import { all, takeEvery, call, put, select } from 'redux-saga/effects';
import { all, takeEvery, call, put, select } from 'typed-redux-saga';
import { loginSucceeed, loginFailed, login } from '../domains/auth/AuthActions';
// import {
//   fetchCatalogueSucceeed,
//   fetchCatalogueFailed,
//   fetchCatalogue,
// } from '../domains/shop/ShopActions';
import { selectUser, selectAuth } from '../domains/auth/AuthSelectors';
// import { selectCartId } from '../domains/shop/ShopSelectors';
import { apiRequest, apiRequestFailed, apiRequestSucceeed } from './ApiActions';
import { apiFecth, apiPost, apiPut, apiDelete } from './ApiServices';
import { selectPushUserId } from '../domains/pushNotifications/PushNotificationsSelectors';
import { environment } from '../config/environments';
import { takeLatest } from '@redux-saga/core/effects';
import { isSatusCodeValid } from '../utils/utils';
import { selectShop } from '../domains/shop/ShopSelectors';
import { sortOrdersByDate } from '../domains/shop/ShopUtils';

const { API_ENDPOINT } = environment;

function* onAppStart(action: any) {}

function* request(action: any) {
  const { requestName, payload } = action;

  if (requestName === 'login') {
    const url = `${API_ENDPOINT}/auth/local`;
    const headers = {
      'Content-Type': 'application/json',
    };

    const pushUserId = yield* select(selectPushUserId);

    const options = {
      identifier: payload.email,
      password: payload.password,
      pushUserId,
    };

    try {
      yield put(login());

      const result = yield* call(apiPost, url, headers, options);
      const response = yield* call(() => result.json());

      if (response?.user?.id || isSatusCodeValid(response.statusCode)) {
        yield* put(loginSucceeed(response));
      } else {
        const message = response.message[0].messages[0].message || '';
        yield* put(loginFailed({ message }));
      }
    } catch (error: any) {
      yield* put(loginFailed({ message: error?.message }));
    }
  } else if (requestName === 'loginSocial') {
    const url = `${API_ENDPOINT}/auth/${payload.provider}/callback${payload.search}`;
    const headers = {};

    try {
      yield* put(login());

      const result = yield* call(apiFecth, url, headers);
      const response = yield* call(() => result.json());

      if (response?.user?.id) {
        yield put(loginSucceeed(response));
      } else {
        const message = response.message[0].messages[0].message || '';
        yield put(loginFailed({ message, isSocial: true }));
      }
    } catch (error: any) {
      yield put(loginFailed({ message: error?.message }));
    }
  } else if (requestName === 'fetchOrders') {
    const { id: shopId } = yield* select(selectShop);
    const { jwt } = yield* select(selectAuth);

    const url = `${API_ENDPOINT}/orders/shop/${shopId}`;

    const headers = {
      Authorization: `Bearer ${jwt}`,
    };

    try {
      const result = yield* call(apiFecth, url, headers);
      const response = yield* call(() => result.json());

      if (Array.isArray(response)) {
        yield put(
          apiRequestSucceeed({
            requestName,
            response: {
              orders: response,
              sortedOrders: sortOrdersByDate(response),
            },
            url,
          })
        );
      } else {
        yield put(
          apiRequestFailed({
            requestName,
            response,
            errorMessage: response.message,
            url,
          })
        );
      }
    } catch (error: any) {
      apiRequestFailed({
        errorMessage: error?.message,
        requestName,
        response: {},
        url,
        payload: {},
      });
    }
  } else if (requestName === 'validateOrder') {
    const { orderId } = payload;
    const { jwt } = yield* select(selectAuth);

    const url = `${API_ENDPOINT}/orders/validate`;
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    };
    const options = {
      orderId,
    };

    try {
      const result = yield* call(apiPost, url, headers, options);
      const response = yield* call(() => result.json());

      if (response.order?.id) {
        yield put(apiRequestSucceeed({ requestName, response, url }));
        yield* put(apiRequest('fetchOrders'));
      } else {
        yield put(
          apiRequestFailed({
            requestName,
            response,
            errorMessage: response.message,
            url,
          })
        );
        alert(response.message);
      }
    } catch (error: any) {
      apiRequestFailed({
        errorMessage: error?.message,
        requestName,
        response: {},
        url,
        payload: {},
      });
    }
  } else if (requestName === 'markOrderAsReady') {
    const { orderId } = payload;
    const { jwt } = yield* select(selectAuth);

    const url = `${API_ENDPOINT}/orders/ready`;
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    };
    const options = {
      orderId,
    };

    try {
      const result = yield* call(apiPost, url, headers, options);
      const response = yield* call(() => result.json());

      if (response.order?.id) {
        yield put(apiRequestSucceeed({ requestName, response, url }));
        yield* put(apiRequest('fetchOrders'));
      } else {
        yield put(
          apiRequestFailed({
            requestName,
            response,
            errorMessage: response.message,
            url,
          })
        );
        alert(response.message);
      }
    } catch (error: any) {
      apiRequestFailed({
        errorMessage: error?.message,
        requestName,
        response: {},
        url,
        payload: {},
      });
    }
  } else if (requestName === 'markOrderAsPickedUp') {
    const { orderId } = payload;
    const { jwt } = yield* select(selectAuth);

    const url = `${API_ENDPOINT}/orders/picked-up`;
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    };
    const options = {
      orderId,
    };

    try {
      const result = yield* call(apiPost, url, headers, options);
      const response = yield* call(() => result.json());

      if (response.order?.id) {
        yield put(apiRequestSucceeed({ requestName, response, url }));
        yield* put(apiRequest('fetchOrders'));
      } else {
        yield put(
          apiRequestFailed({
            requestName,
            response,
            errorMessage: response.message,
            url,
          })
        );
        alert(response.message);
      }
    } catch (error: any) {
      apiRequestFailed({
        errorMessage: error?.message,
        requestName,
        response: {},
        url,
        payload: {},
      });
    }
  }

  // Stop progress indicator if any
  if (typeof payload.stopRefreshIndicator === 'function') {
    payload.stopRefreshIndicator();
  }
}

const apiSagas = function* apiSagas(): any {
  yield all([
    takeEvery('API_REQUEST', request),
    // takeEvery('APP_START', onAppStart)
  ]);
};

export default [apiSagas];
