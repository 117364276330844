import React from 'react';
import { IonText } from '@ionic/react';
import s from './Divider.module.scss';

interface IProps {
  label?: string;
  style?: any;
}

const Divider: React.FC<IProps> = ({ label, style }) => {
  return (
    <div className={s.container} style={style}>
      {!label ? (
        <hr />
      ) : (
        <>
          <hr />
          <IonText style={{ padding: '0 10px' }}>{label}</IonText>
          <hr />
        </>
      )}
    </div>
  );
};

export default Divider;
