/**
 *
 * Auth
 * Higher Order Component that blocks navigation when the user is not logged in
 * and redirect the user to login page
 *
 * Wrap your protected routes to secure your container
 */

import React, { ReactType } from 'react';
import { Redirect, Route } from 'react-router-dom';
import authUtils from '../domains/auth/authUtils';

interface Iprops {
  component: ReactType;
  path: string;
  exact?: boolean;
}

const ProtectedRoute = ({ component: Component, ...rest }: Iprops) => (
  <Route
    {...rest}
    render={(props) =>
      authUtils.getToken() !== null ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/auth/login',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

export default ProtectedRoute;
