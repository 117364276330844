import React, { useCallback } from 'react';
import {
  IonAvatar,
  IonBadge,
  IonButton,
  IonCard,
  IonCardContent,
  IonIcon,
  IonImg,
  IonItem,
  IonRow,
  IonText,
} from '@ionic/react';
import {
  bagCheckOutline,
  checkmark,
  checkmarkCircle,
  checkmarkCircleOutline,
  checkmarkDone,
  closeSharp,
  logoEuro,
} from 'ionicons/icons';
import moment from 'moment';
import { IOrder } from '../../domains/shop/ShopTypes';
import styles from './Order.module.scss';

const calendarRule = {
  sameDay: '[Today] HH:mm',
  nextDay: '[Tomorrow] HH:mm',
  nextWeek: 'dddd HH:mm',
  lastDay: '[Yesterday] HH:mm',
  lastWeek: '[Last] dddd HH:mm',
  sameElse: 'dddd Do HH:mm',
};
interface IProps {
  order: IOrder;
  validateOrder: (ordrrId: number) => any;
  markOrderAsReady: (ordrrId: number) => any;
  markOrderAsPickedUp: (ordrrId: number) => any;
}

const Order: React.FC<IProps> = ({
  order,
  validateOrder,
  markOrderAsReady,
  markOrderAsPickedUp,
}) => {
  const _validateOrder = useCallback(() => {
    validateOrder(order.id);
  }, [order.id, validateOrder]);

  const _markOrderAsReady = useCallback(() => {
    markOrderAsReady(order.id);
  }, [order.id, markOrderAsReady]);

  const _markOrderAsPickedUp = useCallback(() => {
    markOrderAsPickedUp(order.id);
  }, [order.id, markOrderAsPickedUp]);

  return (
    <IonCard key={order.id} className={styles.root}>
      <IonRow>
        <IonCardContent style={{ flex: 1 }}>
          <IonText>
            <h3>
              <b>{order.user.username}</b>
            </h3>
          </IonText>
          <IonText>
            <p>{order.cart?.amount}$</p>
          </IonText>
          <IonText>
            <p>{moment(order.requestedDate).calendar(calendarRule)}</p>
            {/* <p>{moment(order.requestedDate).format('MMMM Do YYYY, h:mm a')}</p> */}
            {/* <p>{moment(order.created_at).format('MMMM Do YYYY, h:mm a')}</p> */}
          </IonText>
        </IonCardContent>
        <IonCardContent>
          {!!order.declined ? (
            <IonBadge color="danger">
              <IonIcon icon={closeSharp} />
            </IonBadge>
          ) : (
            <>
              <IonBadge
                color={order.paid ? 'success' : 'light'}
                style={{ marginRight: '6px' }}
              >
                <IonIcon icon={logoEuro} />
              </IonBadge>
              <IonBadge
                color={order.validated ? 'success' : 'light'}
                style={{ marginRight: '6px' }}
              >
                <IonIcon icon={checkmark} />
              </IonBadge>
              <IonBadge
                color={order.ready ? 'success' : 'light'}
                style={{ marginRight: '6px' }}
              >
                <IonIcon icon={checkmarkDone} />
              </IonBadge>
              <IonBadge
                color={order.pickedUp ? 'success' : 'light'}
                style={{ marginRight: '6px' }}
              >
                <IonIcon icon={bagCheckOutline} />
              </IonBadge>
            </>
          )}
        </IonCardContent>
      </IonRow>
      <IonCardContent>
        <div className="">
          {!order.validated && !order.declined && (
            <IonButton size="small" color="light" onClick={_validateOrder}>
              Validate
            </IonButton>
          )}
          {!!order.validated && !order.ready && (
            <IonButton size="small" color="light" onClick={_markOrderAsReady}>
              Mark as ready
            </IonButton>
          )}
          {!!order.ready && !order.pickedUp && (
            <IonButton
              size="small"
              color="light"
              onClick={_markOrderAsPickedUp}
            >
              Mark as Picked Up
            </IonButton>
          )}

          {!order.declined && !order.validated && (
            <IonButton
              size="small"
              color="light"
              onClick={() => console.log('TODO Decline')}
            >
              Decline
            </IonButton>
          )}
        </div>
      </IonCardContent>
      {order.cart?.menusSelections.map(({ id, menu }) => {
        return (
          <IonItem key={id}>
            <IonAvatar className="ion-margin-end">
              <IonImg
                // src={`${environment.API_ENDPOINT}${product.picture?.url}`}
                src={menu.picture?.url || menu.pictureUrl}
                alt=""
              />
            </IonAvatar>
            <IonText>
              <p>1x {menu.name}</p>
            </IonText>
          </IonItem>
        );
      })}
      {order.cart?.productsSelections.map(
        ({ id, product, productQuantity }) => {
          return (
            <IonItem key={id}>
              <IonAvatar className="ion-margin-end">
                <IonImg
                  // src={`${environment.API_ENDPOINT}${product.picture?.url}`}
                  src={product.picture?.url || product.pictureUrl}
                  alt=""
                />
              </IonAvatar>
              <IonText>
                <p>
                  {productQuantity} x {product.name}
                </p>
              </IonText>
            </IonItem>
          );
        }
      )}
    </IonCard>
  );
};

export default Order;
