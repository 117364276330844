import React, { useEffect } from 'react';
import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useHistory } from 'react-router';
import { Dispatch } from 'redux';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './view/theme/variables.css';

import Menu from './components/Menu';
import OrdersScreen from './pages/OrdersScreen';
import { appStart } from './domains/lifecycle/LifeCycleActions';
import AuthScreen from './view/pages/AuthScreen/AuthScreen';
import AuthLoadingScreen from './view/pages/AuthLoadingScreen';
import {
  setInitialApparenceMode,
  // toggleDarkTheme,
} from './domains/theme/theme';
import ProtectedRoute from './view/ProtectedRoute';
import AdsTasksScreen from './pages/AdsTasksScreen';

setInitialApparenceMode();
// toggleDarkTheme(false);
/**
 * Router part
 */

const SwitchRoutes: React.FC = () => {
  /**
   * Store the history object globally so we can access it outside of React components
   * See issue: https://github.com/ionic-team/ionic/issues/20297#issuecomment-625445957
   */
  window.appHistory = useHistory();

  return (
    <Switch>
      <Route path="/auth/:authType?" component={AuthScreen} exact={true} />
      <Route
        path="/auth/:provider/callback"
        component={AuthLoadingScreen}
        exact={true}
      />

      <IonSplitPane contentId="main">
        <Menu />
        <IonRouterOutlet id="main">
          <ProtectedRoute
            path="/orders/:momentKey"
            component={OrdersScreen}
            exact={true}
          />
          <ProtectedRoute
            path="/ads-tasks"
            component={AdsTasksScreen}
            exact={true}
          />
          <Route path="/" exact={true}>
            <Redirect to="/orders/today" />
          </Route>

          {/* <Route path="/menu" exact={true}>
          <Page />
        </Route> */}
        </IonRouterOutlet>
      </IonSplitPane>
    </Switch>
  );
};

const App: React.FC<{ dispatch: Dispatch }> = ({ dispatch }) => {
  useEffect(() => {
    dispatch(appStart());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IonApp>
      <IonReactRouter>
        <SwitchRoutes />
        {/* <IonRouterOutlet id="main">
            <Route path="/" exact={true}>
              <Redirect to="/page/Inbox" />
            </Route>
            <Route path="/page/:name" exact={true}>
              <Page />
            </Route>
          </IonRouterOutlet> */}
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
