import { IShopState } from './ShopTypes';

export const INITIAL_STATE: IShopState = {
  shop: {
    id: 0,
    name: '',
    users_permissions_user: null,
    pictureUrl: '',
    address: '',
    published_at: '',
    created_at: '',
    updated_at: '',
  },
  orders: [],
  sortedOrders: {
    today: [],
    tomorrow: [],
    later: [],
    past: [],
  },
  isOrdersLoading: false,
  orderErrorMessage: '',
  // products: [],
  // loading: false,
  // catalogueErrorMessage: '',
  // cartErrorMessage: '',
  // cartId: 0,
  // cartFull: {
  //   id: 0,
  //   user: 0,
  //   amount: 0,
  //   created_at: '',
  //   updated_at: '',
  //   selections: [],
  // },
  // isCartIsLoading: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case 'LOGIN_SUCCEEDED': {
      return {
        ...state,
        shop: action.user.shop,
      };
    }
    case 'LOGOUT': {
      return { ...INITIAL_STATE };
    }
    // case 'FETCH_CATALOGUE': {
    //   return {
    //     ...state,
    //     loading: true,
    //     catalogueErrorMessage: '',
    //   };
    // }
    // case 'FETCH_CATALOGUE_SUCCEEDED': {
    //   return {
    //     ...state,
    //     products: action.products,
    //     loading: false,
    //     catalogueErrorMessage: '',
    //   };
    // }
    // case 'FETCH_CATALOGUE_FAILED': {
    //   return {
    //     ...state,
    //     loading: false,
    //     catalogueErrorMessage: action.errorMessage || '',
    //   };
    // }
    case 'API_REQUEST': {
      if (action.requestName === 'fetchOrders') {
        return {
          ...state,
          isOrdersLoading: true,
          orderErrorMessage: '',
        };
      }
      return state;
    }
    case 'API_REQUEST_SUCCEEDED': {
      if (action.requestName === 'fetchOrders') {
        return {
          ...state,
          isOrdersLoading: false,
          orderErrorMessage: '',
          orders: action.response.orders,
          sortedOrders: action.response.sortedOrders,
        };
      }
      return state;
    }
    case 'API_REQUEST_FAILED': {
      if (action.requestName === 'fetchOrders') {
        return {
          ...state,
          isOrdersLoading: false,
          orderErrorMessage: action.errorMessage,
        };
      }
      return state;
    }
    default:
      return state;
  }
};
