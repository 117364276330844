export default {
  production: true,
  API_ENDPOINT: `https://pocstrapiionic.herokuapp.com`,
  ONESIGNAL_APP_ID: '6c64cd0a-4e4c-441c-aeb7-c44de9088e21',
  ONESIGNAL_GOOGLE_SENDER_ID: '726068375916',

  // API_ENDPOINT: `http://${devDomain}:1337`,
  // ONESIGNAL_APP_ID: 'e53f3121-9593-4d9d-9b43-38395e1c9a88',
  // ONESIGNAL_GOOGLE_SENDER_ID: '1036300436162',
};
