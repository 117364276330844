import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { IonContent, IonPage, IonText, IonSpinner } from '@ionic/react';
import { apiRequest } from '../../api/ApiActions';
import { IScreenNavigationProps } from '../../domains/navigation/NavigationTypes';

interface IProps extends IScreenNavigationProps {
  loginSocial: (provider: 'google', search: string) => any;
}

class AuthLoadingScreen extends PureComponent<IProps> {
  componentDidMount() {
    const { match, location } = this.props;
    this.props.loginSocial(match.params.provider, location.search);
  }

  render() {
    return (
      <IonPage>
        <IonContent>
          <div className="container">
            <IonText>
              <IonSpinner />
            </IonText>
          </div>
        </IonContent>
      </IonPage>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    loginSocial: (provider: 'google', search: string) => {
      dispatch(
        apiRequest('loginSocial', {
          provider,
          search,
        })
      );
    },
  };
};

export default connect(null, mapDispatchToProps)(AuthLoadingScreen);
