function get(url: string, headers = {}) {
  return fetch(url, {
    method: 'GET',
    headers,
  });
}

function post(url: string, headers = {}, options = {}) {
  return fetch(url, {
    method: 'POST',
    headers,
    body: JSON.stringify(options),
  });
}

function put(url: string, headers = {}, options = {}) {
  return fetch(url, {
    method: 'PUT',
    headers,
    body: JSON.stringify(options),
  });
}

function delete_(url: string, headers = {}, options = {}) {
  return fetch(url, {
    method: 'DELETE',
    headers,
  });
}

export const apiFecth = async (url: string, headers?: object) => {
  const response = await get(url, headers);
  return response;
};

export const apiPost = async (
  url: string,
  headers?: object,
  options?: object
) => {
  const response = await post(url, headers, options);
  return response;
};

export const apiPut = async (
  url: string,
  headers?: object,
  options?: object
) => {
  const response = await put(url, headers, options);
  return response;
};

export const apiDelete = async (url: string, headers?: object) => {
  const response = await delete_(url, headers);
  return response;
};
