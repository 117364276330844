import React from 'react';
import {
  IonBadge,
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
} from '@ionic/react';

import { useLocation } from 'react-router-dom';
import {
  archiveOutline,
  archiveSharp,
  basketOutline,
  basketSharp,
  bookmarkOutline,
  fastFoodOutline,
  fastFoodSharp,
  heartOutline,
  heartSharp,
  listOutline,
  listSharp,
  mailOutline,
  mailSharp,
  megaphoneOutline,
  megaphoneSharp,
  paperPlaneOutline,
  paperPlaneSharp,
  trashOutline,
  trashSharp,
  warningOutline,
  warningSharp,
} from 'ionicons/icons';
import { connect } from 'react-redux';
import { logout } from '../domains/auth/AuthActions';
import {
  selectOrders,
  selectShop,
  selectSortedOrders,
} from '../domains/shop/ShopSelectors';
import { IStoreState } from '../types/ReduxTypes';
import { selectUser } from '../domains/auth/AuthSelectors';
import './Menu.css';
import { sortedOrderMomentKey } from '../domains/shop/ShopTypes';

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
  countKey?: sortedOrderMomentKey;
}

const appPages: AppPage[] = [
  {
    title: "Today's orders",
    url: '/orders/today',
    iosIcon: basketOutline,
    mdIcon: basketSharp,
    countKey: 'today',
  },
  {
    title: "Tomorrow's orders",
    url: '/orders/tomorrow',
    iosIcon: basketOutline,
    mdIcon: basketSharp,
    countKey: 'tomorrow',
  },
  {
    title: "Later's orders",
    url: '/orders/later',
    iosIcon: basketOutline,
    mdIcon: basketSharp,
    countKey: 'later',
  },
  {
    title: 'Past orders',
    url: '/orders/past',
    iosIcon: basketOutline,
    mdIcon: basketSharp,
    countKey: 'past',
  },
];

const itemsBis: AppPage[] = [
  {
    title: 'Menus',
    url: '/menus',
    iosIcon: listOutline,
    mdIcon: listSharp,
  },
  {
    title: 'Products',
    url: '/products',
    iosIcon: fastFoodOutline,
    mdIcon: fastFoodSharp,
  },
];

const itemsMarketing: AppPage[] = [
  {
    title: 'Ads',
    url: '/ads-tasks',
    iosIcon: megaphoneOutline,
    mdIcon: megaphoneSharp,
  },
];

const Menu: React.FC<{
  logout: () => any;
  orderCounts: {
    [key in sortedOrderMomentKey]: number;
  };
  shopName: string;
  shopPictureUrl: string;
  username: string;
}> = (props) => {
  const { logout, shopName, shopPictureUrl, username } = props;
  const location = useLocation();

  return (
    <IonMenu contentId="main" type="overlay">
      {/* <div
        style={{
          width: '100%',
          height: 200,
          // border: '2px solid red',
          backgroundImage: `url(${shopPictureUrl})`,
          backgroundPosition: 'top center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      /> */}
      <IonContent>
        <IonList id="inbox-list">
          <div
            style={{
              marginTop: '20px',
              marginLeft: '8px',
              width: 80,
              height: 80,
              borderRadius: 100,
              // border: '2px solid red',
              backgroundImage: `url(${shopPictureUrl})`,
              backgroundPosition: 'top center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}
          />
          <IonListHeader>{shopName}</IonListHeader>
          <IonNote>{username}</IonNote>
        </IonList>

        <br />
        <br />
        <IonList id="labels-list">
          <IonListHeader>Orders</IonListHeader>
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem
                  className={
                    location.pathname === appPage.url ? 'selected' : ''
                  }
                  routerLink={appPage.url}
                  routerDirection="none"
                  lines="none"
                  detail={false}
                >
                  <IonIcon
                    slot="start"
                    ios={appPage.iosIcon}
                    md={appPage.mdIcon}
                  />
                  <IonLabel>{appPage.title}</IonLabel>
                  {!!(
                    appPage.countKey && props.orderCounts[appPage.countKey]
                  ) && (
                    <IonBadge color="primary">
                      {props.orderCounts[appPage.countKey]}
                    </IonBadge>
                  )}
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>

        <IonList id="labels-list">
          <IonListHeader>The Shop</IonListHeader>
          {itemsBis.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem
                  className={
                    location.pathname === appPage.url ? 'selected' : ''
                  }
                  routerLink={appPage.url}
                  routerDirection="none"
                  lines="none"
                  detail={false}
                >
                  <IonIcon
                    slot="start"
                    ios={appPage.iosIcon}
                    md={appPage.mdIcon}
                  />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>

        <IonList id="labels-list">
          <IonListHeader>Marketing</IonListHeader>
          {itemsMarketing.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem
                  className={
                    location.pathname === appPage.url ? 'selected' : ''
                  }
                  routerLink={appPage.url}
                  routerDirection="none"
                  lines="none"
                  detail={false}
                >
                  <IonIcon
                    slot="start"
                    ios={appPage.iosIcon}
                    md={appPage.mdIcon}
                  />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>

        {/* <IonList id="labels-list">
          <IonListHeader>Labels</IonListHeader>
          {labels.map((label, index) => (
            <IonItem lines="none" key={index}>
              <IonIcon slot="start" icon={bookmarkOutline} />
              <IonLabel>{label}</IonLabel>
            </IonItem>
          ))}
        </IonList> */}

        <div
          style={{
            marginTop: '20px',
            marginLeft: '8px',
          }}
        >
          <IonButton size="small" color="light" onClick={logout}>
            Logout
          </IonButton>
        </div>
      </IonContent>
    </IonMenu>
  );
};

const mapStateToProps = (state: IStoreState) => {
  const sortedOrders = selectSortedOrders(state);
  const shop = selectShop(state);
  const user = selectUser(state);
  return {
    orderCounts: {
      today: sortedOrders.today.length,
      tomorrow: sortedOrders.tomorrow.length,
      later: sortedOrders.later.length,
      past: sortedOrders.past.length,
    },
    shopName: shop?.name,
    shopPictureUrl: shop?.pictureUrl,
    username: user?.username,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    logout: () => {
      dispatch(logout());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
