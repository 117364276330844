import { createLogger } from 'redux-logger';

export const logger = createLogger({
  collapsed: true,
  logErrors: true,
  predicate: (getState, action) =>
    action.type !== '@@redux-form/REGISTER_FIELD' &&
    action.type !== '@@redux-form/UNREGISTER_FIELD' &&
    action.type !== '@@redux-form/UPDATE_SYNC_ERRORS' &&
    action.type !== '@@redux-form/DESTROY' &&
    action.type !== '@@redux-form/INITIALIZE' &&
    action.type !== '@@redux-form/TOUCH' &&
    action.type !== '@@redux-form/BLUR',
  // action.type !== '@@redux-form/FOCUS' &&
  // action.type !== '@@redux-form/CHANGE'
});
