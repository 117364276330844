import { createSelector } from 'reselect';
import { IStoreState } from '../../types/ReduxTypes';
import { IAuthState } from './AuthTypes';

const getAuh: (state: IStoreState) => IAuthState = (state) => state.auth;

export const selectAuth = createSelector(getAuh, (auth) => auth);

export const selectAuthentificated = createSelector(
  getAuh,
  (auth) => auth.authentificated
);

export const selectUser = createSelector(getAuh, (auth) => auth.user);
