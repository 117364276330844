import React, { PureComponent } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import './LoginForm.css';
import {
  IonInput,
  IonItem,
  IonButton,
  IonSpinner,
  IonText,
} from '@ionic/react';

const MyInput = ({ field, form, ...props }: any) => {
  return <IonInput {...field} {...props} onIonChange={field.onChange} />;
};

type Values = {
  email: string;
  password: string;
};

interface IProps {
  onSubmit: (values: Values) => any;
  showLoader: boolean;
}

class LogingForm extends PureComponent<IProps> {
  validate = (values: Values) => {
    const errors: { [key: string]: string } = {};
    if (!values.email) {
      errors.email = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }
    if (!values.password) {
      errors.password = 'Required';
    }
    return errors;
  };

  onSubmit = (values: Values, { resetForm }: any) => {
    this.props.onSubmit(values);
    resetForm();
  };

  render() {
    const { showLoader } = this.props;
    return (
      <div className="LoginForm-container">
        <Formik
          initialValues={{
            email: 'manager@letsbe.com',
            // email: 'delphine@bidonvilla.fr',
            // email: 'oriane@madeinmoon.io',
            password: 'password',
          }}
          validate={this.validate}
          onSubmit={this.onSubmit}
        >
          {({ isSubmitting, submitCount, resetForm }) => (
            <Form>
              <IonItem>
                <Field type="email" name="email" component={MyInput} />
                <ErrorMessage name="email">
                  {(msg) =>
                    !!submitCount && (
                      <>
                        <IonText color="danger">{msg}</IonText>
                      </>
                    )
                  }
                </ErrorMessage>
              </IonItem>
              <IonItem>
                <Field
                  type="password"
                  name="password"
                  component={MyInput}
                  placeholder="Password"
                />
                <ErrorMessage name="password">
                  {(msg) =>
                    !!submitCount && (
                      <>
                        <IonText color="danger">{msg}</IonText>
                      </>
                    )
                  }
                </ErrorMessage>
              </IonItem>

              <br />
              <br />
              <IonButton type="submit" disabled={isSubmitting || showLoader}>
                {showLoader ? <IonSpinner /> : 'Sign In'}
              </IonButton>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default LogingForm;
