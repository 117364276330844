import moment from 'moment';

export const isDev = process.env.NODE_ENV === 'development';

export const isSatusCodeValid = (statusCode: number) =>
  !statusCode ? false : statusCode.toString().startsWith('2');

export const isToday = (date?: string) => {
  if (!date) {
    return false;
  }
  return moment(new Date()).isSame(date, 'day');
};

export const isTomorrow = (date?: string) => {
  if (!date) {
    return false;
  }
  return moment(date).isSame(moment().add(1, 'day'), 'day');
};

export const isBefore = (date?: string) => {
  if (!date) {
    return false;
  }
  return moment(date).isBefore(new Date());
};
