import { combineReducers } from 'redux';
import AuthReducer from '../domains/auth/AuthReducer';
import PushNotificationsReducer from '../domains/pushNotifications/PushNotificationsReducer';
import ShopReducer from '../domains/shop/ShopReducer';
import DataReducer from '../api/DataReducer';

export const reducers = combineReducers({
  auth: AuthReducer,
  pushNotifications: PushNotificationsReducer,
  shop: ShopReducer,
  data: DataReducer,
});
