import devEnv from './environment';
import prodEnv from './environment.prod';

let env: {
  production: boolean;
  API_ENDPOINT: string;
  ONESIGNAL_APP_ID: string;
  ONESIGNAL_GOOGLE_SENDER_ID: string;
};

if (process.env.NODE_ENV === 'production') {
  env = prodEnv;
} else {
  env = devEnv;
}

export { env as environment };
