import { RequestName, Payload, Response } from './ApiTypes';

export const apiRequest = (requestName: RequestName, payload?: Payload) => ({
  type: 'API_REQUEST',
  requestName,
  payload: payload || {},
});

export const apiRequestSucceeed = ({
  requestName,
  response,
  payload,
  url,
}: {
  requestName: RequestName;
  response: Response;
  payload?: Payload;
  url?: string;
}) => ({
  type: 'API_REQUEST_SUCCEEDED',
  requestName,
  response,
  payload: payload || {},
  url,
});

export const apiRequestFailed = ({
  requestName,
  errorMessage,
  response,
  payload,
  url,
}: {
  requestName: RequestName;
  errorMessage: string;
  response: Response;
  payload?: Payload;
  url?: string;
}) => ({
  type: 'API_REQUEST_FAILED',
  requestName,
  errorMessage,
  response,
  payload: payload || {},
  url,
});
