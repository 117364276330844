import { isBefore, isToday, isTomorrow } from '../../utils/utils';
import { IOrders, OrdersSortedType } from './ShopTypes';

export const sortOrdersByDate = (orders: IOrders) => {
  const ordersSorted: OrdersSortedType = {
    today: [],
    tomorrow: [],
    later: [],
    past: [],
  };

  console.log(orders);

  orders.forEach((order) => {
    if (isToday(order.requestedDate)) {
      ordersSorted.today.push(order);
    } else if (isTomorrow(order.requestedDate)) {
      ordersSorted.tomorrow.push(order);
    } else if (isBefore(order.requestedDate)) {
      ordersSorted.past.push(order);
    } else {
      ordersSorted.later.push(order);
    }
  });

  return ordersSorted;
};
