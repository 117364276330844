import { isCapacitor, isIOS, isAndroid } from '../../utils/platform';
import { setFlagsFromString } from 'v8';

const isRealDevice = true;
// const myIP = '192.168.1.70'; // TODO from .env, it should depends from the dev member
const myIP = '92.184.108.46'; // TODO from .env, it should depends from the dev member

let devDomain = 'localhost';

if (isCapacitor) {
  // prevents flase positive when debuggin on Chrome in mobile mode
  if (isIOS && isRealDevice) {
    devDomain = myIP;
  } else if (isAndroid) {
    devDomain = isRealDevice ? myIP : '10.0.2.2';
  }
}

export default {
  production: false,
  API_ENDPOINT: `http://${devDomain}:1337`,
  // API_ENDPOINT: `https://pocstrapiionic.herokuapp.com`,
  ONESIGNAL_APP_ID: 'e53f3121-9593-4d9d-9b43-38395e1c9a88',
  ONESIGNAL_GOOGLE_SENDER_ID: '1036300436162',
};
