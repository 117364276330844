import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import ReduxThunk from 'redux-thunk';
import { reducers } from './reducers';
import { sagas } from './sagas';
import { persistConfig } from './redux-persist';
import { logger } from './redux-logger';
import { isDev } from '../utils/utils';

import 'regenerator-runtime/runtime';

const persistedReducer = persistReducer(persistConfig, reducers);

const sagaMiddleware = createSagaMiddleware();

const middlewares: any = [];
middlewares.push(sagaMiddleware);
middlewares.push(ReduxThunk);
if (isDev) {
  middlewares.push(logger);
}

const configureStore: any = () => {
  const store = createStore(
    persistedReducer,
    compose(applyMiddleware(...middlewares))
  );

  sagaMiddleware.run(sagas);

  const persistor = persistStore(store);
  return { store, persistor };
};

export default configureStore;
